::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

::-webkit-scrollbar-thumb {
  background: #0A0302;
}

::-webkit-scrollbar-track {
  background: #161616;
}

.swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: #EC991C;
}

.swiper-pagination-bullet {
  background: #EC1C1C;
  opacity: 1;
}