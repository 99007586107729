.sendbird-app__wrap {
  height: 78vh;
}

.sendbird-app__channellist-wrap {
  padding: 16px 24px 8px 24px;
  border: none !important;
}

.sendbird-app__conversation-wrap {
  padding: 16px 24px 8px 0;
}

.sendbird-app__channellist-wrap .sendbird-channel-list {
  border-radius: 20px;
}

.sendbird-app__conversation-wrap .sendbird-conversation {
  border-radius: 20px;
  border: 1px solid transparent;
}

.sendbird-channel-list>* .sendbird-ui-header {
  border-radius: 20px 20px 0 0;
}

.sendbird-conversation .sendbird-ui-header {
  border-radius: 20px 20px 0 0;
}

.sendbird-message-content__middle__message-item-body.outgoing {
  background: linear-gradient(90deg, rgba(236, 153, 28, 1) 0%, rgba(236, 28, 28, 1) 100%);
  border-radius: 12px;
}

.sendbird-channel-preview>* .sendbird-channel-preview__content__upper__header__channel-name.sendbird-label.sendbird-label--subtitle-2.sendbird-label--color-onbackground-1 {
  color: rgba(255, 255, 255, 1);
}

.sendbird-conversation__scroll-bottom-button {
  background-color: rgba(236, 153, 28, 1) !important;
}

.sendbird-dropdown__menu {
  background: #161616 !important;
}

.sendbird-avatar {
  border-radius: 20px;
}

.sendbird-theme--light .sendbird-modal__content {
  background: #161616;
}

.sendbird-modal__content .sendbird-user-list-item {
  background: #161616;
}

.sendbird-ui-header .sendbird-ui-header__right,
.sendbird-message-input--attach {
  display: none;
}

.sendbird-channel-preview .sendbird-channel-preview__action {
  display: none;
}